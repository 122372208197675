import { get, put,Delete,post } from '../axios'

// 平台端-活动管理-列表
export const activityList = params => get(`/goods/web/activity/list`,params);
// 平台端-活动管理-新增
export const activityAdd = params => post(`/goods/web/activity/add`,params);
// 平台端-活动管理-是否启用-修改
export const activityStateUp = params => put(`/goods/web/activity/state/update`,params);

// 门店端-活动管理-删除
export const activityDel = params => Delete(`/goods/web/activity/${params}`,params);

// 门店端-活动管理-修改
export const activityUp = params => put(`/goods/web/activity/update`,params);

// 活动商品列表
export const activityGoodsList= params => post(`/goods/web/activity/goodsList`,params);

// 活动商品新增
export const activityGoodsAdd= params => post(`/goods/web/activity/goods/add`,params);

// 活动商品修改
export const activityGoodsUp= params => put(`/goods/web/activity/goods/update`,params);

// 活动商品详情
export const activityView= params => get(`/goods/web/activity/goods/${params}`);

// 活动商品批量删除
export const activityGoodsDel= params => put(`/goods/web/activity/goods`, params);
 
// 活动商品是否启用修改
export const activityGoodsState = params => put(`/goods/web/activity/goods/state/update`,params);

// 活动商品是否必购修改
export const activityGoodsMustBuy = params => put(`/goods/web/activity/goods/mustBuy`,params);

// 活动商品是否限购修改
export const activityGoodsLimitBuy = params => put(`/goods/web/activity/goods/limitBuy`,params);
 

