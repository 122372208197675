<template>
  <div>
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
        <el-form-item label="商品名称：" prop="name">
          <el-input
            v-model="tableFrom.name"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属品牌" prop="brandId">
          <el-select
            v-model="tableFrom.brandId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in brandList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="listLoading"
      :data="tableData.data"
      ref="goodTable"
      size="small"
      @select-all="handleSelectAll"
      @select="selectARow"
    >
      <el-table-column key="2" type="selection" width="55" />
       <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
      <el-table-column label="商品图" min-width="70">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.mainImg"
              :preview-src-list="[scope.row.mainImg]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="store_name" label="商品名称" min-width="200">
        <template slot-scope="scope">
          <div>
            <span
              class="tags_name"
              :style="
                scope.row.specType == 0 ? 'color: #ff8a4d;' : 'color: #4073FA;'
              "
              :class="'name' + scope.row.spec_type"
              >{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
            >{{ scope.row.name || "-" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="brandName" label="品牌名称" min-width="150" />
      <el-table-column prop="salePrice" label="商品售价" min-width="80" />
      <el-table-column prop="actualSaleNum" label="销量" min-width="70" />
      <el-table-column prop="stock" label="库存" min-width="70" />

      <el-table-column prop="sort" label="排序" min-width="60" />
      <el-table-column label="操作" min-width="150" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.cancel_time"
            type="text"
            size="small"
            @click="onDetails(scope.row)"
            >选择链接</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        :page-size="tableFrom.pageSize"
        :current-page="tableFrom.page"
        layout="total, prev, pager, next, jumper"
        :total="tableData.total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>
<script>
import { goodsStorePageList, goodBrandTotalListAll,storePoolExport } from "@/api/goods/goods";
import { exportExcel,exportExcelPost } from "@/assets/utils/exportExcel";
import {getIndex} from "@/utils/other";
export default {
  name: "goodsCardAll",
  data() { 
    return {
      activeName: "1",
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      multipleSelection:[],
      brandList: [], //品牌列表
      tableFrom: { 
        pageNum: 1,
        pageSize: 100,
        checkStatus: "1",
      },
    };
  },
  mounted() {
    this.getList();
    this.getBrandList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    searchReset() {
      this.tableFrom.name = "";
      this.tableFrom.brandId= "";
      this.getList();
    },
    onDetails(row) {
      this.$emit("getGoodsItem", row);
    },
    //导出
    exportFun() {
      let ids =[]
    this.multipleSelection.map((item)=>{
      ids.push(item.id)
      });

      exportExcelPost("/web/goods/store/pool/export", {
        ...this.tableFrom,
        ids: ids.toString(),
        responseType: "blob",
        exportExcelName:'导入商品模板'
      });
      setTimeout(()=>{
        this.multipleSelection=[]
        this.$refs.goodTable.clearSelection();
        this.$forceUpdate()
      },0)

    },
    getList() {
      this.listLoading = true;
      goodsStorePageList({ ...this.tableFrom })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          res.data.records.map((item, index) => {
            this.multipleSelection.map((val, k) => {
              if (item.id == val.id) {
                this.$nextTick(() => {
                  this.$refs.goodTable.toggleRowSelection(item);
                })
              }
            })
          })
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    selectARow(val, row) { //单选Checkbox的事件
      let selectBool = val.length && val.indexOf(row) !== -1   //当前点击是否勾选
      if (!selectBool) {  //取消勾选
        this.multipleSelection.forEach((item, i) => {
          if (item.id == row.id) {
            this.multipleSelection.splice(i, 1)
          }
        })

      } else { //选中
        this.multipleSelection.push(row)
      }
    },
    handleSelectAll(val) {  //全选 Checkbox的事件
      if (val.length == this.tableData.data.length) { //全选
        this.multipleSelection = this.multipleSelection.concat(val)
        //去重
        let obj = {}
        let result = []
        this.multipleSelection.forEach(item => {
          if (!obj[item.id]) {
            result.push(item)
            obj[item.id] = true
          }
        })
        this.multipleSelection = result

      } else { //取消全选
        this.tableData.data.forEach(item => {
          this.multipleSelection.forEach((multItem, i) => {
            if (item.id == multItem.id) {
              this.multipleSelection.splice(i, 1)
            }
          })
        })
      }
    },
    //获取品牌列表
    getBrandList() {
      goodBrandTotalListAll(1).then((res) => {
        this.brandList = res.data;
      });
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style scoped>
</style>
